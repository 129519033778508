<!--
 * @Description: 配置页面
 * @Autor: WangYuan
 * @Date: 2021-06-10 16:01:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-26 00:29:07
-->
<template>
	<div class="body">
		<div class="content">
			<el-form label-position="right" label-width="120px" size="small" class="mb50">
				<el-form-item label="运行模式:">
					<el-radio v-model="project.config.model" :label="1">H5</el-radio>
					<el-radio v-model="project.config.model" :label="2">小程序</el-radio>
					<el-radio v-model="project.config.model" :label="3">公众号</el-radio>
					<div class="f12 f-grey">如果需要同时支持小程序和公众号,需要复制一个单独的对应项目</div>
				</el-form-item>

				<el-form-item label="项目名称：">
					<el-input v-model="project.config.title" style="width: 200px"></el-input>
					<div class="f12 f-grey">项目简称</div>
				</el-form-item>

				<el-form-item label='项目logo'>
					<FaImageUpload :maxCount="1" v-model="project.config.logo" />
				</el-form-item>

				<el-form-item label="客多赞商户号：">
					<el-input v-model="project.config.kdzAppId" style="width: 200px"></el-input>
					<div class="f12 f-grey">系统后台对应客多赞的账号</div>
				</el-form-item>

				<el-form-item label="客多赞密钥：">
					<el-input v-model="project.config.kdzAppSercet" style="width: 200px"></el-input>
					<div class="f12 f-grey">对应客多赞的密钥</div>
				</el-form-item>

				<el-form-item label="客多赞域名：">
					<el-input v-model="project.config.kdzUrl" style="width: 200px"></el-input>
					<div class="f12 f-grey">微信公众号或者小程序的授权域名(必须包含https://)</div>
				</el-form-item>

				<el-form-item label="客多赞辅助域名：">
					<el-input v-model="project.config.kdzWptUrl" style="width: 200px"></el-input>
					<div class="f12 f-grey">微信公众号或者小程序的授权域名(必须包含https://)</div>
				</el-form-item>

				<el-form-item label="微信appId：">
					<el-input v-model="project.config.wxAppId" style="width: 200px"></el-input>
					<div class="f12 f-grey">公众号或小程序的appId</div>
				</el-form-item>

				<el-form-item label="自动注册:">
					<el-radio v-model="project.config.autoRegister" :label="1">是</el-radio>
					<el-radio v-model="project.config.autoRegister" :label="0">否</el-radio>
					<div class="f12 f-grey">开启自动注册后,用户访问页面后系统会主动询问授权获取用户信息并在系统创建对应的记录</div>
					<div class="f12 f-grey">兑换类及预约类建议开启,宣传展示类可以关闭</div>
				</el-form-item>

				<el-form-item label="主色调：">
					<el-color-picker v-model="project.config.maincolor1"></el-color-picker>
					<!-- <el-input v-model="project.config.maincolor1" style="width: 200px"></el-input> -->
					<div class="f12 f-grey">按钮类默认颜色</div>
				</el-form-item>

				<el-form-item label="辅助色调：">
					<el-color-picker v-model="project.config.maincolor2"></el-color-picker>
					<div class="f12 f-grey">默认背景色</div>
				</el-form-item>

			</el-form>
			<div class="f12 f-grey">配置后请点击右上角的保存按钮进行保存</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations,
		mapGetters
	} from "vuex";

	export default {
		name: "setting-pages",
		data() {
			return {};
		},
		computed: {
			...mapGetters(["project"]),
			homePage() {
				return this.project.pages.find((page) => page.home);
			},
		},
		mounted() {
			console.log(this.project);
		},
		methods: {
			...mapMutations(["setCurPage"]),


			// 编辑页面
			editPage(id) {
				//console.log("编辑页面", this.project.pages, id);
				let page = this.project.pages.find((page) => (page.id == id));
				//console.log('当前打开的页面的参数', page);
				//如果没有 config: {}, //页面背景图 标题 分享图片和文字等配置 就补充一个
				console.log("-------->有没有config", page.hasOwnProperty('config'));
				if (!page.hasOwnProperty('config')) {
					page.config = {
						backgroundColor: '',
					}; //页面背景图 标题 分享图片和文字等配置	
				}


				this.setCurPage(page);
				this.$router.push({
					name: "page-build"
				});
			},





		},
	};
</script>

<style lang="scss" scoped>
	input {
		border: 0;
		font-size: 13px;
		/*no*/
	}

	.body {
		padding: 15px;
		overflow: auto;
		/*no*/

		.content {
			width: 100%;
			/*no*/
			height: 700px;
			/*no*/
			padding: 40px 20px;
			/*no*/
			background: #fff;

			.content-head {
				display: flex;
				align-items: flex-start;
				padding: 20px 30px;
				/*no*/
				margin-top: 15px;
				background: #f7f8fa;
			}
		}
	}
</style>